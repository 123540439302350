<template>
  <modal-wrapper>
    <template v-slot="{ close }">
      <confirmation-modal
        ref=""
        :close="close"
        :content="content"
        @confirm="submit"
      ></confirmation-modal>
    </template>
    <template v-slot:trigger="{ open }">
      <slot :click="open"></slot>
    </template>
  </modal-wrapper>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useErrorHandler } from '@/application/composables/responseErrorHandler'
import { useModal } from '@/application/composables/modal'

export default {
  name: 'ComplexConfirmationModal',
  components: {
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
  },
  props: {
    confirmButton: {},
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      required: true,
    },
    handleConfirmation: {
      type: Function,
      required: true,
    },
    close: {
      type: Function,
    },
  },
  setup(props) {
    const { responseErrorHandler } = useErrorHandler()
    const { openModal } = useModal()

    return {
      submit: () => {
        return props.handleConfirmation()
          .then((response) => response)
          .catch((error) => {
            const status = error.response.status
            openModal(responseErrorHandler(status))
            return error
          })
      },
    }
  },
}
</script>
